const DashboardEndpoints = {
  getDashboard(reqData: any) {
    return {
      url: "main/dashboard/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  blockUser(reqData: any) {
    return {
      url: "partner/block/admin",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default DashboardEndpoints;
