import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppContextProvider } from "./AppContext/App.context";
import appReducer from "./AppContext/reducers/reducer";
import { ToastContextProvider } from "./components/Toast/Toast.context";
import { ToastRightContextProvider } from "./components/ToastRight/ToastRight.context";
import toastReducer from "./components/Toast/reducers/reducer";
import toastRightReducer from "./components/ToastRight/reducers/reducer";
import useLocalStorage from "./utils/localStorage";
import "./i18n";
import PageLoader from "./components/pageLoader/pageLoader";

// import {
//   initializeFirebase,
//   askForPermissionToReceiveNotificaitons,
// } from "./push-notification";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  // typography: {
  //   fontFamily: `'ProximaNova-Regular','Sans-Serif'`
  // },
  palette: {
    primary: {
      main: "#3c7bf6",
      dark: "#3c7bf6",
      light: "#3c7bf6",
    },
  },
});
const localStorageObject = useLocalStorage;

ReactDOM.render(
  // <React.StrictMode> </React.StrictMode>
  <Suspense fallback={<PageLoader></PageLoader>}>
    <BrowserRouter>
      <AppContextProvider reducer={appReducer}>
        <ToastContextProvider reducer={toastReducer}>
          <ToastRightContextProvider reducer={toastRightReducer}>
            <MuiThemeProvider theme={theme}>
              <App />
            </MuiThemeProvider>
          </ToastRightContextProvider>
        </ToastContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
// initializeFirebase();
// if (!localStorage.getItem("device_token")) {
//   askForPermissionToReceiveNotificaitons();
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
