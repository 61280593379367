import React, { useState, useEffect, useRef, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import "./Datatable.scss";
import DatatableEndpoints from "./Datatable.endpoints";
import PageLoader from "../pageLoader/pageLoader";
import Input from "../../components/Input/Input";
import useDebounce from "./use-debounce";
import PageHeading from "../../components/PageHeading/PageHeading";

const AdvancedPaginationTable = forwardRef((props: any, ref: any) => {
  const [data, setData] = useState([]);
  const [showDatatable, setShowDatatable]: any = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPerPage, setCurrentPerPage] = useState(10);
  const [appData]: any = useAppContext();
  const ApiService = useApiService();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  let sorting: any = {
    sortColumnName: props.defaultSortColumnName || "",
    sortColumnOrder: props.defaultSortColumnOrder || "",
  };
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setShowDatatable(false);
      fetchDataBySearching(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm.trim()]);
  const fetchDataBySearching = async (debouncedSearchTerm: string) => {
    const response: any = await fetchDataFromApi(
      null,
      null,
      debouncedSearchTerm.trim()
    );
    setShowDatatable(true);
    setLoading(false);
  };
  const fetchDataFromApi = async (
    page?: any,
    newPerPage?: any,
    result?: any
  ) => {
    setLoading(true);
    if (!page) {
      page = currentPage;
    }
    if (!newPerPage) {
      newPerPage = currentPerPage;
    }
    setCurrentPage(page);
    setCurrentPerPage(newPerPage);
    let url =
      props.paginationServer === false
        ? props.endPoint
        : props.endPoint +
          `?skip=${(page - 1) * newPerPage}&limit=${newPerPage}`;
    if (props.extraParams) {
      for (let i = 0; i < props.extraParams.length; i++) {
        const param = props.extraParams[i];
        url += `&${param["name"]}=`;
        if (typeof param["value"] === "object") {
          url += encodeURIComponent(JSON.stringify(param["value"]));
        } else {
          url += param["value"];
        }
      }
    }
    if (result) {
      url += `&search=${result}`;
    }
    if (sorting["sortColumnName"] && props.paginationServer !== false) {
      url +=
        "&order_column=" +
        sorting["sortColumnName"] +
        "&order_by=" +
        sorting["sortColumnOrder"].toUpperCase();
    }
    const reqData = {
      url: url,
      headers: {
        access_token: appData.loginData.access_token,
      },
    };
    if (props.extraParams) {
      const response = await ApiService.get(
        DatatableEndpoints.getData(reqData)
      );
      // const response:any = {data:{"message":"success","status":200,"data":[{"job_id":87,"user_id":1,"job_status":0,"task_id":85,"task_description":"test","local_datetime":"2020-10-14T11:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T05:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":87,"user_id":1,"job_status":0,"task_id":86,"task_description":"test","local_datetime":"2020-10-14T10:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T04:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":87,"user_id":1,"job_status":0,"task_id":87,"task_description":"test","local_datetime":"2020-10-14T11:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T05:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":87,"user_id":1,"job_status":0,"task_id":88,"task_description":"test","local_datetime":"2020-10-14T10:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T04:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":88,"user_id":1,"job_status":0,"task_id":null,"task_description":null,"local_datetime":null,"timezone":null,"utc_datetime":null,"location":null,"lat":null,"lng":null,"task_status":null,"resource_id":null},{"job_id":89,"user_id":1,"job_status":0,"task_id":null,"task_description":null,"local_datetime":null,"timezone":null,"utc_datetime":null,"location":null,"lat":null,"lng":null,"task_status":null,"resource_id":null},{"job_id":90,"user_id":1,"job_status":0,"task_id":null,"task_description":null,"local_datetime":null,"timezone":null,"utc_datetime":null,"location":null,"lat":null,"lng":null,"task_status":null,"resource_id":null}]}}
      response.data.data ? setData(response.data.data) : setData(response.data);
      return response;
    }
  };

  const handlePageChange = (page: any) => {
    fetchDataAndSetTotalRows(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setLoading(true);

    const response: any = await fetchDataFromApi(page, newPerPage);
    setPerPage(newPerPage);
    setLoading(false);
  };
  const fetchDataAndSetTotalRows = async (page?: any) => {
    const response: any = await fetchDataFromApi(page, null);
    setTotalRows(response.data.count);
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      fetchDataAndSetTotalRows();
    });
  }, [props.refreshDataTable]);
  const handleSort = (column: any, sortDirection: string) => {
    setLoading(true);
    sorting["sortColumnName"] = column.selector;
    sorting["sortColumnOrder"] = sortDirection;
    if (props.getSortData) {
      props.getSortData(column.selector, sortDirection);
    }
    fetchDataAndSetTotalRows(1);
  };
  return (
    <div>
      {props.title === "Resource List" ||
      props.title === "Team List" ||
      props.title === "Supervisor List" ||
      props.title === "Dashboard Users" ||
      props.title === "Customers List" ? (
        <div className="header">
          <PageHeading title={props.title} />
          <div className={"searchWord"}>
            <Input
              id="search"
              className={"search"}
              name="search"
              label="Search"
              type="search"
              onchange={(e: any) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {showDatatable ? (
        <DataTable
          persistTableHead={true}
          // fixedHeader={true}
          title={props.title}
          columns={props.columns}
          data={data}
          defaultSortField={props.defaultSortColumnName}
          defaultSortAsc={props.defaultSortColumnOrder === "ASC" ? true : false}
          onSort={handleSort}
          sortServer={props.sortServer === false ? false : true}
          progressPending={loading}
          progressComponent={<PageLoader />}
          pagination
          paginationServer={props.paginationServer === false ? false : true}
          paginationResetDefaultPage={true}
          paginationTotalRows={totalRows}
          selectableRows={props.selectableRows || false}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      ) : (
        ""
      )}
    </div>
  );
});
export default AdvancedPaginationTable;
