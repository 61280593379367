import React, { useState, Fragment, useEffect } from "react";
import clsx from "clsx";
import Header from "../Header/Header";
import "./HeaderSidebarWrapper.module.scss";
import icon_to_close_sidebar from "../../assets/images/icon_to_close_sidebar.svg";
import icon_to_open_sidebar from "../../assets/images/open-side-menu.svg";
import smallLogo from "../../assets/images/no.png";
import largeLogo from "../../assets/images/logo.svg";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsActive";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TimelineIcon from "@material-ui/icons/Timeline";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MapIcon from "@material-ui/icons/Map";
import { useHistory, useLocation } from "react-router";
import headerDropdownService from "../../services/headerDropdown.service";
import { Subject } from "rxjs";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import useLocalStorage from "../../utils/localStorage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: "0 0 5px 0 #e5e9f2",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  fakeMenuButton: {
    "&:hover, &:focus": {
      background: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  largeLogo: {
    width: "200px",
    padding: "10px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  selected: {
    // height: "70px",
    "&.Mui-selected": {
      color: "#fff",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "calc(100% + 15px)",
      background: "#3c7bf6",
      "& svg": {
        color: "#fff",
      },
      "&:hover, &:focus": {
        background: "#3c7bf6",
        color: "#fff",
      },
    },
  },
  floatingIcon: {
    position: "absolute",
    bottom: "25px",
  },
  list: {
    height: "calc(100vh - 160px)",
  },
}));
// const ListItem1 = withStyles({
//   root: {
//     "&$selected": {
//       backgroundColor: "#3c7bf6",
//       color: "#fff"
//     },
//     "&$selected:hover": {
//       backgroundColor: "#3c7bf6",
//       color: "#fff"
//     }
//   },
//   selected: {}
// })(MuiListItem);
const subject = new Subject();

export const sidebarSubject = {
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (sidebarData: any) => {
    subject.next(sidebarData);
  },
};
const HeaderSidebarWrapper = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [settingsListOpen, setSettingsListOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const Permission: any = useLocalStorage.getItem("Permission");
  let APIKey: any = true;
  let PermissionsArr: any = Permission ? Object.values(Permission) : [];
  if (PermissionsArr.length) {
    PermissionsArr.map((val: any) => {
      if (!val) {
        APIKey = false;
        return;
      }
    });
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const goToHome = () => {};
  const openCretaeTaskFromSidebar = () => {
    const dropdownData = {
      type: "clickedOnCreateTask",
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
  };

  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={goToHome}
            edge="start"
            className={clsx(classes.menuButton, classes.fakeMenuButton, {
              [classes.hide]: open,
            })}
          >
            <img src={smallLogo} />
          </IconButton>
          <Header></Header>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawerClose}>

            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
          <img src={largeLogo} className={classes.largeLogo} />
          <img src={icon_to_close_sidebar} onClick={handleDrawerClose} />
        </div>

        <List className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <img src={icon_to_open_sidebar} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push("/dashboard");

              const resetAnalytics = {
                type: "resetAnalytics",
                // data: { clicked: true },
              };
              sidebarSubject.emit(resetAnalytics);
            }}
            className={classes.selected}
            button
            key="Dashboard"
            selected={location.pathname == "/dashboard"}
          >
            <ListItemIcon>
              {/* <img  
              src={dashboard_sidebar_icon}
              alt="header logo" classes={classes}
            /> */}
              <DashboardOutlinedIcon color={"action"}></DashboardOutlinedIcon>
            </ListItemIcon>
            {open ? <ListItemText primary="Dashboard" /> : ""}
          </ListItem>

          <ListItem
            onClick={() => {
              history.push("/analytics");

              const resetDashboard = {
                type: "resetDashboard",
                // data: { clicked: true },
              };
              sidebarSubject.emit(resetDashboard);
            }}
            selected={location.pathname === "/analytics"}
            className={classes.selected}
            button
            key="Jobs"
          >
            <ListItemIcon>
              <TimelineIcon color={"action"}></TimelineIcon>
              {/* <img src={task_sidebar_icon} alt="task logo" /> */}
            </ListItemIcon>
            {open ? <ListItemText primary="Analytics" /> : ""}
          </ListItem>

          <ListItem
            onClick={() => {
              history.push("/profile");
              const resetAnalytics = {
                type: "resetAnalytics",
                // data: { clicked: true },
              };
              sidebarSubject.emit(resetAnalytics);

              const resetDashboard = {
                type: "resetDashboard",
                // data: { clicked: true },
              };
              sidebarSubject.emit(resetDashboard);
            }}
            className={classes.selected}
            selected={location.pathname === "/profile"}
            button
            key="Resources"
          >
            <ListItemIcon>
              <PersonOutlineOutlinedIcon
                color={"action"}
              ></PersonOutlineOutlinedIcon>
              {/* <img src={ResourceSidebarIcon} alt="resource logo" /> */}
              {/* <ResourceSidebarIcon></ResourceSidebarIcon> */}
            </ListItemIcon>
            {open ? <ListItemText primary="Profile" /> : ""}
          </ListItem>
        </List>
      </Drawer>
      {/* <Header></Header>
      <SideMenu></SideMenu> */}
    </Fragment>
  );
};
export default HeaderSidebarWrapper;

// {TabPermission && TabPermission[RolePermissions.Read_APIKey] ? (
//   <ListItem
//   className={classes.selected}
//   button
//   selected={location.pathname.includes("/settings/apikey")}
//   onClick={() => {
//     history.push("/settings/apikey");
//   }}
// >
//   <ListItemIcon>
//     <VpnKeyIcon
//       color={"action"}
//     ></VpnKeyIcon>
//     {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
//   </ListItemIcon>
//   {open ? <ListItemText primary="API Key" /> : ""}
// </ListItem>
// ): ( "" )}
