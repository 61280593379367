import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import headerDropdownService from "../../services/headerDropdown.service";
import partnerPortalEndpoints from "../partnerPortal.endpoints";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PageHeading from "../../components/PageHeading/PageHeading";
import useLocalStorage from "../../utils/localStorage";
import useToast from "../../components/Toast/hooks/useToast";
import { socketSubject } from "../../utils/socket";

const Analytics = () => {
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const [analyticsData, setAnalyticsData]: any = useState();
  const [monthlyTransactionsData, setMonthlyTransactionsData]: any = useState(
    []
  );
  const [months, setMonths]: any = useState();

  const [isLoading, setIsLoading]: any = useState(true);
  const toaster = useToast();
  useEffect(() => {
    let headerSubscription: any = headerDropdownService.subscribe(
      eventFromService
    );
    let sidebar: any = socketSubject.subscribe(eventFromService);
    // console.log(headerSubscription);
    // useLocalStorage.removeItem('startDate')
    // useLocalStorage.removeItem('endDate')
    useLocalStorage.removeItem("monthObject");

    GetAnalytics();
    return () => {
      headerSubscription.unsubscribe();
      sidebar.unsubscribe();
    };
  }, []);

  const eventFromService = (eventData: any) => {
    switch (eventData.type) {
      case "startDateChanged":
        GetAnalytics(eventData.data, "start");
        break;
      case "endDateChanged":
        GetAnalytics(eventData.data, "end");
        break;
      // case "resetAnalytics":
      //   GetAnalytics();
      //   break;

      default:
        break;
    }
  };
  function getMonthDateRange(year: any, month: any) {
    let startDate: any = moment([year, month - 1]);
    var endDate = moment(startDate).endOf("month");

    let end = moment(endDate).format("YYYY-MM-DD H:mm");
    let time = "00:00";
    let start = moment(startDate).subtract(0, "days").format("YYYY-MM-DD");
    startDate = moment(start + " " + time);
    return { start: moment(startDate).format("YYYY-MM-DD H:mm"), end: end };
  }
  const setStartMonth = () => {
    let d: any = new Date();
    d.setMonth(d.getMonth() - 2);
    return moment(new Date(d)).format("YYYY-MM-01 00:00");
  };
  const GetAnalytics = async (data?: any, type?: any) => {
    let headers = {
      access_token: access_token,
    };

    let startDate = useLocalStorage.getItem("startDate");
    let endDate = useLocalStorage.getItem("endDate");
    let date: any;
    if (data) {
      if (type === "start") {
        date = getMonthDateRange(data.year, data.month);
        startDate = date.start;
        useLocalStorage.setItem("startDate", startDate);
        let dateObj = new Date();
        let month = dateObj.getUTCMonth() + 1;
        let year = dateObj.getUTCFullYear();
        let temp = getMonthDateRange(year, month);
        endDate = endDate ? endDate : temp.end;
      }

      if (type === "end") {
        startDate = startDate ? startDate : setStartMonth();
        date = getMonthDateRange(data.year, data.month);
        endDate = date.end;
        useLocalStorage.setItem("endDate", endDate);
      }
    } else {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1;
      let year = dateObj.getUTCFullYear();
      date = getMonthDateRange(year, month);
      startDate = startDate ? startDate : setStartMonth();
      endDate = endDate ? endDate : date.end;
    }

    let qureyParams: any = {
      start_date: startDate,
      end_date: endDate,
    };
    console.log(qureyParams);
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    let monthData: any = [];

    if (moment(startDate).isBefore(moment(endDate))) {
      await ApiService.get(partnerPortalEndpoints.analytics(reqData))
        .then((response: any) => {
          setAnalyticsData(response.data);
          let series: any = [];
          for (let i = 0; i < response.data.length; i++) {
            let data: any = [];

            let obj: any = response.data[i]["data"];
            for (let key in obj) {
              if (obj.hasOwnProperty(key)) {
                if (monthData.includes(key) === false) {
                  monthData.push(key);
                }
                data.push(obj[key]["count"]);
              }
            }
            series.push({
              name: response.data[i]["name"],
              data: data,
            });
          }
          console.log(series);
          console.log(monthData);
          // console.log(months)
          setMonths(monthData);
          setMonthlyTransactionsData(series);

          setIsLoading(false);
        })
        .catch((error: any) => {
          toaster.addToast({
            message: error.message,
            type: "error",
            timeout: 3000,
          });
        });
    } else {
      toaster.addToast({
        message: "Start Date Can't Be Greater Than End Date",
        type: "error",
        timeout: 3000,
      });
    }
  };

  let monthlyTransactions = {
    title: {
      text: "Monthly Transactions",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: months && months.length > 0 ? months : [null],
    },
    series:
      monthlyTransactionsData && monthlyTransactionsData.length > 0
        ? monthlyTransactionsData
        : [{ name: "", data: [0] }],
  };
  let monthlyRevenue = {
    title: {
      text: "Monthly Revenue",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: ["2021-03", "2021-04", "2021-05"],
    },
    series: [
      {
        name: "Client 1",
        data: [0, 40, 10],
      },
      {
        name: "Client 2",
        data: [80, 20, 30],
      },
      {
        name: "Client 3",
        data: [10, 46, 78],
      },
    ],
  };
  return (
    <>
      {isLoading ? (
        ""
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeading title={"Analytics"}></PageHeading>
            <div style={{ marginLeft: "1.3rem", marginRight: "1.3rem" }}>
              <Typography>
                Check the monthly trends of your clients jobs and revenue
              </Typography>
              <br></br>
              <br></br>
              <Grid container direction={"row"} justify={"space-around"}>
                <Grid item xs={5}>
                  {/* <div style={{width:"40%"}}> */}
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={monthlyTransactions}
                  ></HighchartsReact>
                  {/* </div> */}
                </Grid>
                <Grid item xs={5}>
                  {/* <div style={{width:"40%"}}> */}
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={monthlyRevenue}
                  ></HighchartsReact>
                  {/* </div> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Analytics;
