import {
  Backdrop,
  Button,
  Fade,
  Grid,
  Input,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { Fragment, Suspense, useEffect, useRef, useState } from "react";
import DataTable from "../../components/Datatable/Datatable";
import { useTranslation } from "react-i18next";
import PageHeading from "../../components/PageHeading/PageHeading";
import Register from "../Register/Register";
// import "./DashboardUsers.scss";
import useLocalStorage from "../../hooks/localStorage";
import moment from "moment";
import headerDropdownService from "../../services/headerDropdown.service";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import DashboardEndpoints from "../Dashboard/Dashboard.endpoints";
import useLoader from "../../hooks/useLoader";
import useToast from "../../components/Toast/hooks/useToast";
import Btn from "../../components/Button/Button";
import { Phone } from "@material-ui/icons";
import { register } from "../../serviceWorker";
import classes from "./DashboardUsers.module.scss";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    "@media (max-width: 375px)": {
      width: "300px",
      height: "700px",
      marginLeft: "35px",
      paddingRight: "20px",
    },
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "300px",
    borderRadius: "6px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  back: {
    display: "flex",
    // justifyContent: "center",
    placeContent: "space-evenly",
    alignItems: "center",
    marginTop: "10px",
  },
}));
const Dashboardusers = () => {
  let [countRefreshDataTable, setCountRefreshDataTable] = useState(0);
  const [
    extraParamsForAnalyticsApiHit,
    setExtraParamsForAnalyticsApiHit,
  ]: any = useState([]);
  const { t, i18n } = useTranslation(["Common", "Customerlist"]);
  const [loading, setLoading]: any = useState(true);
  const openRegisterRef: any = useRef();
  // const [showDetails, setShowDetails] = useState(false);
  let [count, setCount]: any = useState(0);
  const [appData]: any = useAppContext();
  const ApiService = useApiService();
  const loader: any = useLoader();
  let userType = appData.loginData?.user_type;

  const toaster = useToast();
  const classes = useStyles();
  const [popup, handlePopup]: any = useState(false);
  const [latestUserID, setLatestUserId]: any = useState();
  let dataColumns: any = [];
  const columns: any = [
    {
      name: t("Customerlist:User Id"),
      selector: "user_id",
      wrap: true,
      sortable: true,
      // cell: (row: any) => (
      //   <div style={{ marginLeft: "20px" }}>{row["customer_id"]}</div>
      // ),x
    },
    {
      name: t("Common:Email ID"),
      selector: "email",
      wrap: true,
      // sortable: true,
    },
    {
      name: t("Common:Company"),
      selector: "company_name",
      wrap: true,
      // sortable: true,
    },
    // {
    //   name: t("Common:Plan"),
    //   selector: "email",
    //   wrap: true,
    //   sortable: true,
    // },
    {
      name: t("Common:Jobs"),
      selector: "jobs",
      wrap: true,
      // sortable: true,
    },
    {
      name: t("Common:Invoice"),
      selector: "invoice",
      wrap: true,
      // sortable: true,
    },
    {
      name: t("Common:Commission"),
      selector: "commission",
      wrap: true,
      // sortable: true,
    },
  ];
  // userType=2;
  if (userType === 1) {
    let obj2 = {
      name: t("Common:Block"),
      wrap: true,
      center: true,
      cell: (row: any) => {
        return (
          <>
            <Grid container justify="center">
              <Btn
                className={"blockButton"}
                onClick={() => {
                  handlePopup(true);
                  setLatestUserId(row);
                  //   handleOnBlock(row["user_id"]);
                }}
                type={"button"}
                btnText={row.is_active === 1 ? "Block" : "UnBlock"}
              ></Btn>
            </Grid>
          </>
        );
      },
    };
    columns.splice(6, 0, obj2);
  }
  const handleOnBlock = async () => {
    loader.showLoader();
    let isActive;
    if (latestUserID?.is_active === 1) {
      isActive = 0;
    } else {
      isActive = 1;
    }
    const reqBody = {
      user_id: latestUserID?.user_id,
      is_active: isActive,
    };

    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    return ApiService.put(DashboardEndpoints.blockUser(reqData))
      .then((res: any) => {
        loader.hideLoader();
        handlePopupClose();
        setCountRefreshDataTable(++countRefreshDataTable);
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "sucess",
        });
      })
      .catch((err) => {
        loader.hideLoader();
        handlePopupClose();

        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  useEffect(() => {
    useLocalStorage.removeItem("startDate");
    useLocalStorage.removeItem("endDate");
    setParams();

    let headerSubscription: any = headerDropdownService.subscribe(
      eventFromService
    );
    return () => {
      headerSubscription.unsubscribe();
    };
  }, [count]);

  const eventFromService = (eventData: any) => {
    console.log(eventData.type);
    switch (eventData.type) {
      case "DateChanged":
        // setParams()
        setCount(++count);
        break;
      case "openRegisterModal":
        openRegisterRef.current.handleOpen(0);
        break;
      case "refreshTaskList":
        setCountRefreshDataTable(++countRefreshDataTable);
        break;
      default:
        break;
    }
  };
  function getMonthDateRange(year: any, month: any) {
    let startDate: any = moment([year, month - 1]);
    var endDate = moment(startDate).endOf("month");
    console.log(moment(endDate).format("YYYY-MM-DD H:mm"));
    let end = moment(endDate).format("YYYY-MM-DD H:mm");
    let time = "00:00";
    let start = moment(startDate).subtract(0, "days").format("YYYY-MM-DD");
    startDate = moment(start + " " + time);
    return { start: moment(startDate).format("YYYY-MM-DD H:mm"), end: end };
  }

  const setParams = () => {
    let date: any;
    if (useLocalStorage.getItem("monthObject")) {
      let data = useLocalStorage.getItem("monthObject");
      date = getMonthDateRange(data.year, data.month);
    } else {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1;
      let year = dateObj.getUTCFullYear();
      date = getMonthDateRange(year, month);
    }

    let temp: any = [];
    temp.push({
      name: "start_date",
      value: date.start,
    });

    temp.push({
      name: "end_date",
      value: date.end,
    });

    setExtraParamsForAnalyticsApiHit(temp);
    setCountRefreshDataTable(++countRefreshDataTable);
    setLoading(false);
  };
  const handlePopupClose = () => {
    handlePopup(false);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <PageHeading title={"Monthly Commissions"}></PageHeading>
          <div style={{ marginLeft: "1.3rem" }}>
            <Typography>
              Check your monthly commissions for every Client
            </Typography>
          </div>
          <br></br>
          {loading ? (
            ""
          ) : (
            <Grid>
              {/* <div style={{marginLeft:"1.3rem",marginRight:"1.3rem"}}> */}

              <DataTable
                refreshDataTable={countRefreshDataTable}
                defaultSortColumnOrder={"ASC"}
                title={t("Customerlist:Dashboard Users")}
                extraParams={extraParamsForAnalyticsApiHit}
                // data={columns}
                defaultSortColumnName={"user_id"}
                endPoint="partner/dashboard/fetch"
                columns={columns}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Suspense fallback={<h3>Loading&hellip;</h3>}>
        <Fragment>
          <Modal
            // aria-labelledby="register-modal-title"
            aria-describedby="register-modal-description"
            open={popup}
            className={classes.modal}
            onClose={handlePopupClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={popup}>
              <div className={classes.paper}>
                <span style={{ justifyContent: "center" }}>
                  Are you sure to block this user?
                </span>
                <div className={classes.back}>
                  <Btn
                    // className={classes["container__row__back"]}
                    onClick={handlePopupClose}
                    type="cancel"
                    variant="inherit"
                    btnText="cancel"
                  ></Btn>
                  <Btn
                    type="submit"
                    // className={classes["container__row__next"]}
                    onClick={handleOnBlock}
                    btnText="Proceed"
                  ></Btn>
                </div>
              </div>
            </Fade>
          </Modal>
        </Fragment>
      </Suspense>
      <Suspense fallback={<h3>Loading&hellip;</h3>}>
        <Register ref={openRegisterRef}></Register>
      </Suspense>
    </>
  );
};

export default Dashboardusers;
