import React, { useState, Fragment, useRef, useEffect } from "react";
import { IToast } from "../Toast/interfaces";
import classes from "./Toast.module.scss";
import { useToastRightContext } from "./ToastRight.context";

const ToastRight: React.FC = () => {
  const [toastsRight]: any = useToastRightContext();

  const toastPos: any = useRef();
  console.log("ToastRight is called", toastsRight);

  return (
    <div
      className={classes["toast-container-right"]}
      // style={toastPos.current}
    >
      {toastsRight.map((item: IToast) => {
        toastPos.current = item.pos;

        return (
          <Fragment>
            {item.type === "error" ? (
              <div
                className={classes["toast-container-right__toast"]}
                key={item.id}
              >
                <p className={classes["toast-container-right__text"]}>
                  {item.message} {console.log(item.pos)}
                </p>
                {item ? (item.inputref ? item.inputref() : null) : null}
              </div>
            ) : (
              <div
                className={classes["toast-container__success-right-toast"]}
                key={item.id}
              >
                <p className={classes["toast-container__success-right-text"]}>
                  {item.message}
                </p>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
  // }

  //   return (
  //     <div className={classes["toast-container"]}
  //     // style={toastPos.current}
  //     >
  //       {toasts.map((item: IToast) => {
  //         toastPos.current = item.pos;
  //         { console.log(item.pos) }
  //         return (
  //           <Fragment>
  //             {item.type === "error" ? (
  //               <div className={classes["toast-container__toast"]} key={item.id}>
  //                 <p className={classes["toast-container__text"]}>
  //                   {item.message} {console.log(item.pos)}
  //                 </p>
  //                 {item ? (item.inputref ? item.inputref() : null) : null}
  //               </div>
  //             ) : (
  //                 <div
  //                   className={classes["toast-container__success-toast"]}
  //                   key={item.id}
  //                 >
  //                   <p className={classes["toast-container__success-text"]}>
  //                     {item.message}
  //                   </p>
  //                 </div>
  //               )}
  //           </Fragment>
  //         );
  //       })}
  //     </div>
  //   );
  // }
};
export default React.memo(ToastRight);
