import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import RegisterEndpoints from "./Register.endpoints";

import useToast from "../../components/Toast/hooks/useToast";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import { useTranslation } from "react-i18next";
import useLoader from "../../hooks/useLoader";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Input from "../../components/Input/Input";
import Btn from "../../components/Button/Button";
import Phone from "../../components/phone-picker/phone-picker";
import headerDropdownService from "../../services/headerDropdown.service";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    "@media (max-width: 375px)": {
      width: "300px",
      height: "700px",
      marginLeft: "35px",
      paddingRight: "20px",
    },
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
}));

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const openDialog = (value?: any) => {
    let v = value === false ? false : true;
    setOpen(v);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const props = {
    open,
    handleClose,
  };
  return [openDialog, props];
};

const Register = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Register"]);
  const toaster = useToast();
  const loader: any = useLoader();
  const ApiService = useApiService();
  const [openDialog, dialogProps]: any = useDialog();
  const [appData]: any = useAppContext();
  const classes = useStyles();
  useImperativeHandle(ref, () => ({
    handleOpen(operation: any) {
      if (operation === 0) {
        openDialog(true);
      }
    },
  }));
  useEffect(() => {}, []);

  const { register, handleSubmit, errors } = useForm();

  const obj = {
    name: {
      required: ValidationService.requiredValidator(),
    },
    email: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.emailValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
  };
  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };
  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState("91");

  const submitRegisterForm = (data: any) => {
    if (!data.name.trim()) {
      toaster.addToast({
        message: t("Register: Please enter valid name"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {
      name: data.name,
      email: data.email.trim(),
      country_code: dialCode.current,
      phone_number: parseInt(data.phone),
    };
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.post(RegisterEndpoints.registerPartner(reqData))
      .then((res: any) => {
        loader.hideLoader();
        // console.log(res);
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        const eventData = {
          type: "refreshTaskList",
          data: true,
        };
        headerDropdownService.emit(eventData);
        dialogProps.handleClose();
      })
      .catch((error: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: error.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  return (
    <Fragment>
      <Modal
        aria-labelledby="register-modal-title"
        aria-describedby="register-modal-description"
        open={dialogProps.open}
        className={classes.modal}
        onClose={dialogProps.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={dialogProps.open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit(submitRegisterForm)}>
              <h2 id="modal-title">{"Register Client"}</h2>
              {/* Name */}
              <Input
                className="Register__Input"
                type="text"
                id="name"
                name="name"
                refValue={register(obj.name)}
                errors={errors}
                label={t("Register: Name") + "*"}
              ></Input>
              {/* Email */}
              <Input
                type="text"
                id="email"
                name="email"
                refValue={register(obj.email)}
                errors={errors}
                label={t("Register: Email") + "*"}
              ></Input>
              {/* Phone */}
              <Phone
                hideLabel={true}
                showPhone={true}
                setDialCode={(dialcode: any, countrycode: any) => {
                  fetchDialCode(dialcode, countrycode);
                }}
                name="phone"
                id="phone"
                label={"Phone Number"}
                refValue={register(obj.phone)}
                placeholder={t("Register:Enter Mobile Number") + "*"}
                disabled={false}
                required={true}
                errors={errors}
                defaultPhone={preSavedCountryCode}
              ></Phone>
              {/* Submit */}
              <Btn
                className={classes.btn}
                type={"Submit"}
                btnText={t("Register:Submit")}
              ></Btn>
            </form>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default Register;
