import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router";
// import { useAppContext } from "./AppContext/App.context";
import useLocalStorage from "./hooks/localStorage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import EditProfile from "./containers/EditProfile/EditProfile";
import Analytics from "./containers/Analytics/Analytics";
import Profile from "./containers/Profile/Profile";
import Dashboardusers from "./containers/DashboardUsers/Dashboardusers";

// import GoDaddy from "./containers/Domains/GoDaddy/GoDaddy";
const Login = lazy(() => import("./containers/Login/Login"));
const ResetPassword = lazy(() =>
  import("./containers/ResetPassword/ResetPassword")
);

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
// eslint-disable-next-line
const whyDidYouRender = require("@welldone-software/why-did-you-render");

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/login">
        {useLocalStorage.getItem("isAuthenticated") ? (
          <Redirect to="/dashboard"></Redirect>
        ) : (
          <Login></Login>
        )}
      </Route>
      <Route exact path="/">
        <Redirect to="/login"></Redirect>
      </Route>

      <Route exact path="/resetpassword">
        <ResetPassword></ResetPassword>
      </Route>

      {/* <PrivateRoute
        exact
        path="/settings/notifications/"
        component={Notifications}
      /> */}

      <PrivateRoute exact path="/editprofile" component={EditProfile} />
      {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
      <PrivateRoute exact path="/analytics" component={Analytics} />
      <PrivateRoute exact path="/dashboard" component={Dashboardusers} />

      <PrivateRoute exact path="/profile" component={Profile} />
    </Switch>
  );
};

AppRoutes.whyDidYouRender = true;

export default AppRoutes;
