import { RefObject, useEffect, useRef } from "react";
const keyMappings = {
  DOWN: 40,
  UP: 38,
  ENTER: 13,
};
export const useNavigationKeys = (
  ref: RefObject<any>,
  onItemSelect: Function
) => {
  // starting the index from -1 as 0th element is 1st.
  let scrollIndex = useRef(-1);
  // whenever list changes we reset the index
  const resetScrollIndex = () => {
    ref.current?.children[scrollIndex.current]?.classList.remove("active");
    scrollIndex.current = -1;
  };
  // attaching the listeners for keys and taking actions on particular
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (!ref.current) {
        return;
      }
      /*
        We check for 3 events, up, down and enter, we add active class and remove active 
        class on last one, and change the index, and on enter pressed we send enter event.
      */
      switch (event.keyCode) {
        case keyMappings.UP:
          event.preventDefault();
          if (scrollIndex.current > 0) {
            ref.current.children[scrollIndex.current].classList.remove(
              "active"
            );
            scrollIndex.current -= 1;
            ref.current.children[scrollIndex.current]?.classList.add("active");
          }
          break;
        case keyMappings.DOWN:
          event.preventDefault();
          if (scrollIndex.current !== ref.current.children.length - 1) {
            ref.current.children[scrollIndex.current]?.classList.remove(
              "active"
            );
            scrollIndex.current += 1;
            ref.current.children[scrollIndex.current]?.classList.add("active");
          }
          break;
        case keyMappings.ENTER:
          if (scrollIndex.current > -1) {
            onItemSelect(scrollIndex.current);
          }
          break;
        default:
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [ref, onItemSelect]);
  return resetScrollIndex;
};
