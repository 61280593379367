const ProfileEndpoints = {
  getPartner(reqData: any) {
    return {
      url: "partner/partner/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  partnerUpdate(reqData: any) {
    return {
      url: "partner/partner/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default ProfileEndpoints;
