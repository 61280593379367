import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import focusInput from "../../components/Toast/hooks/focusInput";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EditProfileEndpoints from "./EditProfile.endpoints";
import useToast from "../../components/Toast/hooks/useToast";
import useLoader from "../../hooks/useLoader";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import Btn from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import useLocalStorage from "../../utils/localStorage";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "-25px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "auto",
  },
  basicinfo: {
    padding: "12px",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "0px !important",
  },
  pageheading: {
    fontSize: "22px",
  },
  root: {
    width: "100%",
    marginLeft: "5%",
  },
  btn: {
    margin: theme.spacing(2),
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const [showErrormsg, setShowErrormsg]: any = useState(true);
  const [showErrormsgPass, setShowErrormsgPass]: any = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inputFocusPassword, setInputFocusPassword]: any = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [open, setOpen] = React.useState(false);
  const [appData]: any = useAppContext();
  const toaster = useToast();
  const loader: any = useLoader();
  const ApiService = useApiService();
  const localStorage = useLocalStorage;

  const userId = appData.loginData.user_id;

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    getValues: getValues2,
    reset: reset2,
  } = useForm({
    mode: "onChange",
  });
  const clearFields = () => {
    reset2({
      password: "",
      confirmpassword: "",
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const changeInputFocusPassword = (data: any) => {
    let temp = { ...inputFocusPassword };
    temp[data] = false;
    setInputFocusPassword({ ...temp });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validator = {
    confirmPassword: {
      required: "You must specify a password",
      validate: (value: any) => {
        console.log(getValues2()["newPassword"]);
        if (value === getValues2()["newPassword"]) {
          return true;
        } else {
          return "Passwords does not match";
        }
      },
    },
    newPassword: {
      // required: ValidationService.requiredValidator(),
      validate: (value: any) => {
        let Check = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        // console.log(value)
        if (!Check.test(value)) {
          setShowErrormsg(true);
          return "Password must be at least 8 characters long and must contain one lower case, one uppercase, one numeric and one special character.";
        } else {
          setShowErrormsg(false);
        }
        if (value === getValues2()["confirmPassword"]) {
          console.log(getValues2()["confirmPassword"]);
          errors2["confirmPassword"] = false;
          setShowErrormsgPass(false);
          setShowErrormsg(false);
        } else if (
          getValues2()["confirmPassword"] &&
          value !== getValues2()["confirmPassword"]
        ) {
          return true;
        } else {
          errors2["confirmPassword"] = false;
          setShowErrormsgPass(false);
          setShowErrormsg(false);
          return true;
        }
      },
    },
  };

  const submitResetPassword = (data: any) => {
    if (
      data.newPassword &&
      data.confirmPassword &&
      data.newPassword === data.confirmPassword
    ) {
      setShowErrormsgPass(false);
      const reqBody = {
        user_id: userId,
        new_password: data.newPassword,
      };
      if (data.newPassword.trim() === "") {
        toaster.addToast({
          message: "Please enter a valid password",
          timeout: 3000,
          type: "error",
        });
        return;
      }
      loader.showLoader();
      const headers = {
        access_token: appData.loginData.access_token,
      };
      const reqData = {
        reqBody: reqBody,
        headers: headers,
      };
      return ApiService.put(EditProfileEndpoints.updateProfile(reqData))
        .then((res: any) => {
          console.log("Response IS", res);
          loader.hideLoader();
          // props.refreshData();

          console.log(res.data.access_token);
          let loginData = appData.loginData;
          loginData.access_token = res.data.access_token;
          //loginData.password = data.newPassword;

          localStorage.setItem("loginData", loginData);

          console.log("Response IS", res);
          toaster.addToast({
            message: res.message,
            timeout: 3000,
            type: "success",
          });
          handleClose();
          clearFields();
        })
        .catch((err) => {
          loader.hideLoader();
          toaster.addToast({
            message: err.message,
            timeout: 3000,
            type: "error",
            inputref: () => {
              const temp = focusInput(err.message, inputFocusPassword);
              if (temp) setInputFocusPassword({ ...temp });
              else return;
            },
          });
        });
    }
  };

  return (
    <div className={classes.basicinfo}>
      <h2>Change Password</h2>

      <form
        className="handlePasswordChange"
        onSubmit={handleSubmit2(submitResetPassword)}
      >
        <Grid
          container
          justify="center"
          className={classes.container}
          spacing={6}
        >
          <Grid item xs={6}>
            <Input
              //type={showPassword ? "text" : "password"}
              type="password"
              name="newPassword"
              id="newPassword"
              required={true}
              onchange={(ev: any) => {
                if (ev.target.value === getValues2()["confirmPassword"]) {
                  console.log(getValues2()["confirmPassword"]);
                  errors2["confirmPassword"] = false;
                  setShowErrormsgPass(false);
                  setShowPassword(showConfirmPassword);
                  changeInputFocusPassword("confirmPassword");
                }
              }}
              refValue={register2(validator.newPassword)}
              errors={errors2}
              inputFocus={inputFocusPassword}
              setInputFocus={changeInputFocusPassword}
              placeholder="Enter Password"
              showErrormsg={showErrormsg}
              label="Password"
              // InputProps={{
              //   endAdornment: (
              //     <IconButton
              //       aria-label="toggle password visibility"
              //       onClick={handleClickShowConfirmPassword}
              //     >
              //       {showPassword ? '' : ''}
              //     </IconButton>
              //   ),
              // }}
            ></Input>
          </Grid>

          <Grid item xs={6}>
            <Input
              type={showPassword ? "text" : "password"}
              // type="password"
              name="confirmPassword"
              id="confirmPassword"
              required={true}
              refValue={register2(validator.confirmPassword)}
              errors={errors2}
              inputFocus={inputFocusPassword}
              setInputFocus={changeInputFocusPassword}
              placeholder="Reenter Password"
              showErrormsg={showErrormsgPass}
              label="Confirm Password"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            ></Input>
          </Grid>

          <Grid container justify="center">
            <Btn className={classes.btn} type={"Submit"} btnText="Submit"></Btn>
            <Btn
              className={classes.btn}
              onClick={() => {
                clearFields();
              }}
              color={"default"}
              type={"button"}
              btnText={"Cancel"}
            ></Btn>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ChangePassword;
