export const businessUsecase: any = [
  {
    use_case: 1,
    job_name: "Food Delivery",
  },
  {
    use_case: 2,
    job_name: "Grocery Delivery",
  },

  {
    use_case: 3,
    job_name: "Pick-up & Delivery",
  },

  {
    use_case: 4,
    job_name: "Courier",
  },

  {
    use_case: 5,
    job_name: "Laundry",
  },

  {
    use_case: 6,
    job_name: "Home Services",
  },
  {
    use_case: 7,
    job_name: "Field force Management",
  },
  {
    use_case: 8,
    job_name: "Others",
  },
];
