import * as actionTypes from "../actions/actionTypes";
import useLocalStorage from "../../utils/localStorage";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";
import { userPermissionRoles } from "../../constants/userPermissionRoles";

const localStorage = useLocalStorage;
const ApiService = useApiService();

const onLoginResponse = async (state: any, action: any) => {
  action.data.is_loaded = 0;
  localStorage.setItem("loginData", action.data);
  // const res = await getPermissions(action.data);
  localStorage.removeItem("registerData");

  return {
    ...state,
    isAuthenticated: true,
    loginData: { ...action.data },
  };
};
const getPermissions = async (loginData: any) => {
  if (!Object.keys(loginData).length) {
    return;
  }
  let headers = {
    access_token: loginData.access_token,
  };
  let qureyParams = {
    role_id: loginData.role_id,
    limit: 1,
    skip: 0,
  };
  let reqData = {
    reqBody: qureyParams,
    headers: headers,
  };
  const res: any = await ApiService.get(CommonEndpoints.getRolesList(reqData));
  const roleDetail: any = res.data.data[0];
  var TabPermission: any = {};
  let modpermsvalues: any = Object.values(roleDetail.modperms);
  modpermsvalues.map((mode: any) => {
    let moduleName: any =
      mode.module_alias.charAt(0).toUpperCase() +
      mode.module_alias.slice(1).replace(/_/g, "");
    for (var key in mode.permission) {
      TabPermission[`${userPermissionRoles[key].name}_${moduleName}`] =
        mode.permission[key].access_type;
    }
  });
  localStorage.setItem("Permission", TabPermission);
  // window.location.href = "/dashboard";
  return true;
};

const onLogout = (state: any, action: any) => {
  localStorage.clear();
  return {
    ...state,
    isAuthenticated: false,
    loginData: {},
  };
};

const onLoaded = (state: any, action: any) => {
  return {
    ...state,
    onLoaded: { ...action.data },
  };
};

const app = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.LOGIN_VIA_ACCESS_TOKEN:
    case actionTypes.LOGIN_RESPONSE:
      return onLoginResponse(state, action);
    case actionTypes.LOGOUT:
      return onLogout(state, action);
    case actionTypes.LOADER:
      return onLoaded(state, action);
    // const actionTypes.ON_DROPDOWN_CHANGE_HEADER:
    //     return
    default:
      return state;
  }
};

export default app;
