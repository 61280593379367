interface ILoadParams {
  id: string;
  src: string;
  isAsync?: boolean;
}
export const loadThirdPartyScript = ({
  id,
  src,
  isAsync = true,
}: ILoadParams) => {
  return new Promise((resolve) => {
    if (!(id && src)) {
      throw new Error("One or More required parameters missing...");
    }

    const existingScript = document.getElementById(id);

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = isAsync;

      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };
    }

    if (existingScript) {
      resolve();
    }
  });
};
