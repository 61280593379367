import React, { useRef, useEffect, useState } from "react";
import "./modal.scss";
import Backdrop from "../backdrop/backdrop";
import { CSSTransition } from "react-transition-group";
import { useClickOutside } from "../../hooks/clickOutside";

interface IModalProps {
  shown: boolean;
  width: string;
  height?: string;
  onClose: any;
  padding?: string;
  heading?: string;
  className?: string;
  disableClickOutside?: boolean;
}
const Modal: React.FC<IModalProps> = (props) => {
  // useEffect(() => {
  //   props.shown
  //     ? document.body.classList.add("modal-open")
  //     : document.body.classList.remove("modal-open");
  // }, [props.shown]);
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const modalRef = useRef<HTMLDivElement>(null);
  // checking click outside event of Modal
  useClickOutside(modalRef, () => {
    console.log("this runn");
    if (!props.disableClickOutside) props.onClose();
  });

  const full_screen = {
    width: "100vw",
    top: "0",
    left: "0",
    height: "calc(100vh - 80px)",
  };

  const only_Body = {
    top: "80px",
    left: "250px",
    width: "calc(100vw - 250px)",
    height: "calc(100vh - 80px)",
  };
  return (
    // <Portal>
    <>
      {props.shown ? <Backdrop config={{ color: "#000" }}></Backdrop> : null}
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={props.shown}
        timeout={500}
        classNames="modal-anim"
      >
        <div className="modal">
          <div
            className="modal__container"
            style={size[0] > 991 ? only_Body : full_screen}
          >
            <div
              ref={modalRef}
              className="modal__body"
              style={{
                minWidth: props.width,
                maxWidth: props.width,
                height: props.height,
                padding: props.padding ? props.padding : "0",
              }}
            >
              {props.children}
            </div>
          </div>
        </div>
      </CSSTransition>
      {/* </Portal> */}
    </>
  );
};

export default Modal;
