import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
// const Languages = ["en", "ar"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    nsSeparator: ":",
    fallbackLng: "en",
    lng: "eng",
    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: function () {
        const loadPath = "/translations/{{lng}}/{{ns}}.json";
        // `https://cdn.xyz/FE-translation/default/{{lng}}/{{ns}}.json`;

        return loadPath;
      },
    },
  });

export default i18n;
