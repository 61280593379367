const RegisterEndpoints = {
  registerPartner(reqData: any) {
    return {
      url: "partner/user/partner/register",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default RegisterEndpoints;
