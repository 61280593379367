import { Subject } from "rxjs";

export const subject1 = new Subject();

class GoogleAutocompleteService {
  static autocomplete: any;
  static sessionToken: any;
  static initGoogleClasses() {
    try {
      this.autocomplete = new google.maps.places.AutocompleteService();
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      console.log("noo");
      subject1.next();
    } catch {
      setTimeout(() => {
        this.autocomplete = new google.maps.places.AutocompleteService();
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        console.log("noo");
        subject1.next();
      }, 1000);
    }
  }
  static async getPredictions(obj: any): Promise<any> {
    return new Promise((resolve) => {
      GoogleAutocompleteService.autocomplete.getPlacePredictions(
        {
          ...obj,
          sessionToken: this.sessionToken,
        },
        (
          result: google.maps.places.AutocompletePrediction[],
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(result);
          }else{
            resolve([]);
          }
        }
      );
    });
  }

  static async getlatlong(obj: any): Promise<any> {
    return new Promise((resolve) => {
      const place_id = obj.place_id;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: place_id }, (results, status) => {
        const coordinates = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        resolve(coordinates);
      });
    });
  }

  static async getGeolocation(pos: any): Promise<any> {
    return new Promise((resolve) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: pos,
        },
        (responses: any) => {
          let formatted_address;
          if (responses && responses.length > 0) {
            formatted_address = responses[0].formatted_address;
          } else {
            formatted_address = "Cannot determine address at this location.";
          }
          resolve(formatted_address);
        }
      );
    });
  }
}

export default GoogleAutocompleteService;
