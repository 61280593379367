const partnerPortalEndpoints = {
  analytics(reqData: any) {
    return {
      // API_BASE_URL:"http://54.225.194.218:3000/",
      url: "partner/partner/analytics",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default partnerPortalEndpoints;
