import React, {
  useState,
  useRef,
  useEffect,
  lazy,
  Suspense,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useClickOutside } from "../../hooks/clickOutside";
import GoogleAutocompleteService from "../../utils/googleAutocompleteService";
import "./Autocomplete.scss";
import debounce from "../../utils/debounce";
import locationIcon from "../../assets/images/svgs/location.svg";
import { Position } from "../../utils/geolocation";
import { loadThirdPartyScript } from "../../utils/loadThirdPartyScript";
// import { environment } from "../../utils/environment";
import Modal from "../modal/modal";
import { useNavigationKeys } from "../../hooks/navigationKeys";
import LocationIcon from "./locationIcon";

import useLocalStorage from "../../hooks/localStorage";
import Input from "../Input/Input";
// import { useAppContext } from "../../AppContext/App.context";

interface IAutocomplete {
  address?: any;
  showLocationPicker?: boolean;
  showGeolocationFetcher?: boolean;
  onAutocompleteLocationSelected?: any;
  addToLocalStorage?: boolean;
  prefill?: any;
  markerAddress?: any;
}

const Autocomplete: React.FC<any> = forwardRef((props: any, ref: any) => {
  const GOOGLE_API_KEY = "AIzaSyBkRba8rlBqvQfp_4YBgow6fjfKUvMiPNw";
  let [errors, setError]: any = useState(false);
  useEffect(() => {
    // if(window["google"] && window["google"]["maps"] && window["google"]["maps"]["places"]){
    //   GoogleAutocompleteService.initGoogleClasses()
    // }else{
    loadThirdPartyScript({
      src: `https://maps.googleapis.com/maps/api/js?libraries=geometry,language,places,drawing&amp&key=${GOOGLE_API_KEY}`,
      id: "google-api",
    }).then(() => GoogleAutocompleteService.initGoogleClasses());

    // }
  }, []);

  useEffect(() => {
    if (props.address) {
      changeAutocompleteInputValue(props.address);
    }
  }, [props.address]);

  const [filteredOptions, setFilteredOptions]: any = useState([]);
  const inputRef = useRef<any>();
  const dropdownRef = useRef<any>(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const [showModal, setShowModal]: any = useState(false);
  const [loadingGif, setLoadingGif] = useState(false);
  // const [appData]: any = useAppContext();

  const LocationPicker: any = lazy(() =>
    import("../location-picker/LocationPicker")
  );

  const onLocationSelected = async (index: any) => {
    changeAutocompleteInputValue(filteredOptions[index].description);

    const {
      lat: latitude,
      lng: longitude,
    } = await GoogleAutocompleteService.getlatlong(filteredOptions[index]);
    latLng.current = { latitude, longitude };

    if (props.onAutocompleteLocationSelected) {
      props.onAutocompleteLocationSelected({
        address: inputRef.current.value,
        latLng: latLng.current,
      });
      if (props.addToLocalStorage) {
        useLocalStorage.setItem("location", {
          lat: latLng.current.latitude,
          lng: latLng.current.longitude,
          address: inputRef.current.value,
        });
      }
    }

    setFilteredOptions([]);
  };

  let resetNavigationIndex = useNavigationKeys(dropdownRef, onLocationSelected);

  let latLng: any = useRef(0);

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDropdown(true);
    const obj = {
      input: e.target.value,
    };
    if (e.target.value) {
      debounce(() => fetchSearchResults(obj), 500);
    } else {
      setFilteredOptions([]);
      props.onAutocompleteLocationSelected({});
    }
    resetNavigationIndex();
  };

  const changeAutocompleteInputValue: any = (address: any) => {
    inputRef.current.value = address;
  };

  useImperativeHandle(ref, () => ({
    onMarkerChange() {
      changeAutocompleteInputValue(props.markerAddress.address);
      props.onAutocompleteLocationSelected({
        address: inputRef.current.value,
        latLng: props.markerAddress.latLng,
      });
    },
    onValueChange() {
      changeAutocompleteInputValue(props.value);
      // props.onAutocompleteLocationSelected({
      //   address: inputRef.current.value,
      //   latLng: props.markerAddress.latLng,
      // });
    },
  }));
  // useEffect(() => {
  //   if(props.addToLocalStorage)
  //   inputRef.current.value = useLocalStorage.getItem("location").address
  // })

  const changeLatLng: any = (l: string) => {
    latLng.current = l;
    if (props.onAutocompleteLocationSelected) {
      props.onAutocompleteLocationSelected({
        address: inputRef.current.value,
        latLng: latLng.current,
      });
      if (props.addToLocalStorage) {
        useLocalStorage.setItem("location", {
          lat: latLng.current.latitude,
          lng: latLng.current.longitude,
          address: inputRef.current.value,
        });
      }
    }
  };

  const fetchSearchResults = async (obj: object) => {
    const result = await GoogleAutocompleteService.getPredictions(obj);
    setFilteredOptions(result);
    const elemName = props.name;
    let tempObj = {};
    if (result.length === 0) {
      props.triggerLocationValidate(false);

      setError({ [elemName]: true });
      //  (true);
    } else {
      props.triggerLocationValidate(true);
      setError({ elemName: false });
    }
  };

  // useEffect(() => {
  //   if (loadingGif) {
  //     changeAutocompleteInputValue(appData.fetchApp.address);
  //     changeLatLng({
  //       latitude: appData.fetchApp.latitude,
  //       longitude: appData.fetchApp.longitude,
  //     });
  //   }
  // }, [loadingGif]);

  const fetchGeolocation = async () => {
    setLoadingGif(true);
    const { coords }: any = await Position();
    const location = await GoogleAutocompleteService.getGeolocation(
      new google.maps.LatLng(coords.latitude, coords.longitude)
    );

    changeAutocompleteInputValue(location);
    changeLatLng(coords);
    if (props.onAutocompleteLocationSelected) {
      props.onAutocompleteLocationSelected({
        address: inputRef.current.value,
        latLng: latLng.current,
      });
      if (props.addToLocalStorage) {
        useLocalStorage.setItem("location", {
          lat: latLng.current.latitude,
          lng: latLng.current.longitude,
          address: inputRef.current.value,
        });
      }
    }
    setShowDropdown(false);
  };

  const profileDropDownClosed = () => {
    setShowDropdown(false);
  };

  useClickOutside(dropdownRef, () => {
    profileDropDownClosed();
  });

  return (
    <div className="autocomplete-location__container">
      <div className="autocomplete-location">
        <div className="autocomplete-location__field">
          {props.showLocationPicker ? (
            <div
              onClick={() => setShowModal(true)}
              className="autocomplete-location__show-map-icon"
            >
              <div className="pt-2">
                <LocationIcon fill={props.color} />
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <input
            className="autocomplete-location__input"
            autoComplete="off"
            type="text"
            ref={inputRef}
            name="autocomplete-search"
            onChange={onInputChanged}
            placeholder="Please select a location"
            defaultValue={props.prefill}
          /> */}
          {props.doNotShowMaterialInput ? (
            <input
              type="text"
              className="autocomplete-location__field__plain-input"
              id={props.name || "team-address"}
              name={props.name || "team-address"}
              ref={inputRef}
              value={props.value}
              autoComplete="off"
              onChange={onInputChanged}
              placeholder={
                props.label ? props.label : "Please select a location"
              }
            ></input>
          ) : (
            <Input
              type="text"
              id={props.name || "team-address"}
              name={props.name || "team-address"}
              refValue={inputRef}
              variant={props.variant || "outlined"}
              helperText={props.helperText}
              errors={errors}
              value={props.value}
              onchange={onInputChanged}
              label={props.label ? props.label : "Please select a location"}
            ></Input>
          )}

          {showDropdown ? (
            <ul className="autocomplete-location__box" ref={dropdownRef}>
              {filteredOptions.map((item: any, index: any) => (
                <li key={index} onClick={() => onLocationSelected(index)}>
                  <img src={locationIcon} alt="location-marker" />
                  {item.description}
                </li>
              ))}
            </ul>
          ) : null}
          {/* {props.showGeolocationFetcher ? (
            <img
              onClick={fetchGeolocation}
              alt="target-marker"
              src={targetIcon}
            ></img>
          ) : null} */}
          <Modal
            shown={showModal}
            width={"50vw"}
            height={"60vh"}
            onClose={() => setShowModal(false)}
          >
            {showModal ? (
              <Suspense fallback={<h3>Loading&hellip;</h3>}>
                <LocationPicker
                  address={inputRef.current.value}
                  latLng={latLng.current}
                  changeAutocompleteInputValue={changeAutocompleteInputValue}
                  changeLatLng={changeLatLng}
                  setShowModal={setShowModal}
                />
              </Suspense>
            ) : null}
          </Modal>

          {/* <button
            type="button"
            className="autocomplete-location__btn"
            onClick={() => fetchGeolocation().then(() => setLoadingGif(false))}
            style={{ backgroundColor: props.btnBg }}
          >
            <div className="autocomplete-location__target-icon">
              {loadingGif ? (
                <img
                  src={loadingIcon}
                  alt="loading-gif"
                  className="autocomplete-location__loading-gif"
                />
              ) : (
                <Target fill={props.color} />
              )}
            </div>
            {props.val ? (
              <div
                className="autocomplete-location__target-value"
                style={{ minWidth: props.val !== undefined ? "70px" : "0" }}
              >
                {loadingGif ? "Loading..." : props.val}
              </div>
            ) : null}
          </button> */}
        </div>
      </div>
    </div>
  );
});

export default Autocomplete;
