import React, { Fragment, useEffect, useRef, useState } from "react";
import "./Profile.scss";
import ProfileEndpoints from "./Profile.endpoints";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import useToast from "../../components/Toast/hooks/useToast";
import useLoader from "../../hooks/useLoader";
import userDefaultIcon from "../../assets/images/resources/user.png";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import CommonEndpoints from "../../services/commonApi.service";

import Grid from "@material-ui/core/Grid";
import Input from "../../components/Input/Input";
import Dropdown from "../../components/Dropdown/Dropdown";
import Btn from "../../components/Button/Button";
import Phone from "../../components/phone-picker/phone-picker";
import useLocalStorage from "../../hooks/localStorage";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    // float: "right"
  },
  item: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "0px !important",
  },
  submitBtn: {
    marginTop: "2rem",
  },
}));

const Profile = (props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Profile", "Common"]);
  const ApiService = useApiService();
  const classes = useStyles();
  const [appData]: any = useAppContext();
  const email = appData.loginData.email;
  const access_token = appData.loginData.access_token;
  const { register, handleSubmit, errors, getValues, reset } = useForm();

  const toaster = useToast();
  const [isLoading, setIsLoading] = React.useState(true);

  const obj = {
    //user_image: {},
    name: {
      required: ValidationService.requiredValidator(),
    },
    email: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.emailValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
    region: {
      required: ValidationService.requiredValidator(),
    },
    bank: {
      required: ValidationService.requiredValidator(),
    },
    accountNumber: {
      required: ValidationService.requiredValidator(),
    },
  };

  const [profiledata, setProfileData]: any = useState({
    name: "",
    email: "",
    phone: "",
    region: "",
    country_code: "",
    partnership_plan: "",
    bank: "",
    account_number: "",
    user_image: "",
  });

  useEffect(() => {
    useLocalStorage.removeItem("monthObject");
    useLocalStorage.removeItem("startDate");
    useLocalStorage.removeItem("endDate");
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    let headers = {
      access_token: access_token,
    };
    let queryParams = {
      email: email,
    };
    let reqData = {
      reqBody: queryParams,
      headers: headers,
    };
    const res: any = await ApiService.get(ProfileEndpoints.getPartner(reqData));
    const userData: any = res.data;
    setPrefillProfile(userData);
    setProfileData(userData);
  };

  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState("91");
  const setPrefillProfile = (res: any) => {
    reset({
      name: res[0].name,
      email: res[0].email,
      phone: res[0].phone_number,
      region: res[0].region,
      bank: res[0].bank,
      accountNumber: res[0].account_number,
    });
    if (res[0].country_code) {
      updatePreSavedCountryCode(
        res[0].country_code
          ? res[0].country_code.substring(1)
          : preSavedCountryCode
      );
    }
    if (res[0].user_image) {
      setImage(res[0].user_image);
    }
    // console.log(image);

    setIsLoading(false);
  };

  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };

  const [showDropdown, setShowDropdown]: any = useState(false);
  const [planType, setPlanType]: any = useState();
  const loader: any = useLoader();

  const handleProfileSubmit = (data: any) => {
    var numberCheck = /\d/g;
    if (!data.name.trim() || data.name.trim().match(/^\d/)) {
      toaster.addToast({
        message: "Please enter valid name",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!data.region.trim() || numberCheck.test(data.region.trim())) {
      toaster.addToast({
        message: "Please enter valid region",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!data.accountNumber.trim()) {
      toaster.addToast({
        message: "Please enter valid account number",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!data.bank.trim() || numberCheck.test(data.bank.trim())) {
      toaster.addToast({
        message: "Please enter valid bank",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {
      partner_id: profiledata[0].partner_id,
      // user_image:'',
      name: data.name,
      email: data.email,
      // new_password: '',
      country_code: dialCode.current,
      // phone_number: parseInt(data.phone),
      region: data.region,
      bank: data.bank,
      account_number: data.accountNumber,
      user_image: image,
    };
    // if(image){
    //   request.append("image_type", image);
    // }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.put(ProfileEndpoints.partnerUpdate(reqData))
      .then((res: any) => {
        loader.hideLoader();

        // useLocalStorage.setItem("loginData", res.data);
        let loginData = appData.loginData;
        loginData.name = res.data.name;
        loginData.email = res.data.email;
        loginData.access_token = res.data.access_token;
        // loginData.user_image = image;
        useLocalStorage.setItem("loginData", loginData);

        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  // Image Upload
  const [image, setImage]: any = useState(userDefaultIcon);
  const [
    isShownUploadProfilePictureIcon,
    showUploadProfilePictureIcon,
  ] = useState(false);
  const fileUpload: any = React.createRef();
  const showFileUpload = () => {
    fileUpload.current.click();
  };
  const ImageHandler = async (e: any) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("entity", "partner");

    const res = await ApiService.postFormData(
      CommonEndpoints.uploadImage(formData)
    );
    setImage(res.data.url);
  };

  return (
    <Fragment>
      {isLoading ? (
        <div></div>
      ) : (
        <div className="Profile">
          <div className={"Profile__page-heading"}>{t("Profile: Profile")}</div>
          <div className={"Profile__page-content"}>
            <div className="Profile__page-content__details">
              {t("Profile: Add your personal details")}
            </div>
            <form
              className="Profile__page-content__changeInfo"
              onSubmit={handleSubmit(handleProfileSubmit)}
            >
              <Grid container>
                <Box clone order={{ xs: 2, sm: 1 }}>
                  <Grid
                    container
                    item
                    xs={11}
                    sm={7}
                    md={6}
                    xl={6}
                    alignItems="center"
                    spacing={1}
                  >
                    {/* Name */}
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        refValue={register(obj.name)}
                        errors={errors}
                        label={t("Common: Name") + "*"}
                      ></Input>
                    </Grid>
                    {/* Email */}
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        disabled={"true"}
                        refValue={register(obj.email)}
                        errors={errors}
                        label={t("Common: Email") + "*"}
                      ></Input>
                    </Grid>
                    {/* Phone */}
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <Phone
                        hideLabel={true}
                        showPhone={true}
                        setDialCode={(dialcode: any, countrycode: any) => {
                          fetchDialCode(dialcode, countrycode);
                        }}
                        name="phone"
                        id="phone"
                        label={"Phone Number"}
                        refValue={register(obj.phone)}
                        placeholder={t("Common:Enter Mobile Number") + "*"}
                        disabled={true}
                        required={true}
                        errors={errors}
                        defaultPhone={preSavedCountryCode}
                      ></Phone>
                    </Grid>
                    {/* Region */}
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <Input
                        type="text"
                        id="region"
                        name="region"
                        refValue={register(obj.region)}
                        errors={errors}
                        label={t("Common: Region") + "*"}
                      ></Input>
                    </Grid>
                    {/* Account NUmber */}
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <Input
                        type="text"
                        id="accountNumber"
                        name="accountNumber"
                        refValue={register(obj.accountNumber)}
                        errors={errors}
                        label={t("Common: Account Number") + "*"}
                      ></Input>
                    </Grid>
                    {/* bank */}
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <Input
                        type="text"
                        id="bank"
                        name="bank"
                        refValue={register(obj.bank)}
                        errors={errors}
                        label={t("Common: Bank") + "*"}
                      ></Input>
                    </Grid>
                    {/* Partnership Plans */}
                    {/* <Grid item xs={6}>
                      <Dropdown
                          labelName={t("Profile:Partnership Plan")}
                          // className={""}
                          showDropdown={showDropdown}
                          value={planType}
                          onChange={(e: any) => {
                              setPlanType(e.target.value);
                          }}
                          itemList={[1, 2, 3]}
                          selectedValue={planType}
                          uniqueId="use_case"
                          label="plan_name"
                      ></Dropdown> 
                  </Grid> */}
                    {/* Submit */}
                    <Grid container item justify="center">
                      <Btn
                        className={classes.submitBtn}
                        type={"Submit"}
                        btnText={t("Common:Submit")}
                      ></Btn>
                    </Grid>
                  </Grid>
                </Box>
                {/* Image Upload */}
                <Box clone order={{ xs: 1, sm: 2 }}>
                  <Grid
                    container
                    item
                    xs={11}
                    sm={5}
                    md={5}
                    xl={5}
                    justify="center"
                    // alignItems="center"
                  >
                    <div className="Profile__picture-container">
                      <Grid
                        className={classes.item}
                        item
                        xs={12}
                        md={11}
                        xl={11}
                      >
                        <img
                          src={image}
                          alt="user icon"
                          onMouseEnter={() =>
                            showUploadProfilePictureIcon(true)
                          }
                          onMouseLeave={() =>
                            showUploadProfilePictureIcon(false)
                          }
                        ></img>
                        {isShownUploadProfilePictureIcon ? (
                          <div
                            onClick={showFileUpload}
                            onMouseEnter={() =>
                              showUploadProfilePictureIcon(true)
                            }
                            onMouseLeave={() =>
                              showUploadProfilePictureIcon(false)
                            }
                            className="Profile__picture-container__camera"
                          >
                            <CameraAltOutlinedIcon color="inherit"></CameraAltOutlinedIcon>
                          </div>
                        ) : (
                          ""
                        )}
                        <input
                          accept="image/*"
                          type="file"
                          className="Profile__picture-container__upload"
                          onChange={ImageHandler}
                          ref={fileUpload}
                        ></input>
                      </Grid>
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </form>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default Profile;
