import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";

interface IBtnLoaderProps {
  onClick?: Function;
  btnText: string;
  className?: string;
  type: string;
  color?: string;
  variant?: string;
  disable?: boolean;
  size?: string;
  startIcon?: any;
}
const theme = {
  spacing: 8,
};
const Btn: React.FC<IBtnLoaderProps> = (props: any) => {
  return (
    <Fragment>
      <Button
        type={props.type}
        className={props.className}
        onClick={props.onClick}
        variant={props.variant || "contained"}
        size={props.size}
        startIcon={props.startIcon || ""}
        color={props.color ? props.color : "primary"}
        disabled={props.disable ? props.disable : false}
      >
        {props.btnText}
      </Button>
    </Fragment>
  );
};

export default Btn;
