import React, { useState, useRef, useEffect } from "react";
import "./phone-picker.scss";
import { countriesData } from "./countries";
import { useClickOutside } from "../../hooks/clickOutside";
import { ErrorMessage } from "@hookform/error-message";
import cross from "../../assets/images/cross.svg";
// import tick from "../../assets/images/tick.svg";

const Phone: React.FC<any> = (props: any) => {
  const [showCountryCodes, setShowCountryCodes] = useState(false);
  const phone_number = useRef<any>("");
  const dial_code = useRef<any>("");
  const dropdownRef: any = useRef();

  const [selectedCountry, setSelectedCountry] = useState({
    country: "India",
    dialCode: "91",
    countryCode: "in",
  });

  useEffect(() => {
    if (props.defaultPhone) {
      splitDefaultPhone();
    } else {
      props.setDialCode(selectedCountry.dialCode, selectedCountry.countryCode);
    }
    // eslint-disable-next-line
  }, [props.defaultPhone]);

  const splitDefaultPhone = () => {
    console.log(countriesData);
    dial_code.current = countriesData.filter((country) =>
      props.defaultPhone.startsWith(country.dialCode)
    );
    setSelectedCountry(dial_code.current[0]);
    props.setDialCode(
      dial_code.current[0].dialCode,
      dial_code.current[0].countryCode
    );
    phone_number.current = props.defaultPhone.slice(
      dial_code.current[0].dialCode.length
    );
  };

  useClickOutside(dropdownRef, () => {
    setShowCountryCodes(false);
  });

  const selectCountryHandler = (country: any) => {
    props.setDialCode(country.dialCode, country.countryCode);
    setSelectedCountry(country);
    setShowCountryCodes((prev) => !prev);
  };

  return (
    <React.Fragment>
      <div className="phone">
        {props.hideLabel ? null : (
          <label className="phone__title py-2" style={props.labelStyle}>
            {props.label}
            {props.required ? <span className="required"> {"*"}</span> : null}
          </label>
        )}
        <div className="phone__wrapper">
          {props.showPhone ? (
            <React.Fragment>
              <div
                className="phone__dropdown-btn"
                onClick={() => {
                  if (props.disabled) {
                    return;
                  }
                  setShowCountryCodes((prev) => !prev);
                }}
                style={props.styleAddressForm?.dropdownBtn}
              >
                <span
                  className={"flag flag-" + selectedCountry.countryCode}
                ></span>
              </div>
              {showCountryCodes ? (
                <ul ref={dropdownRef} className="phone__dropdown">
                  {countriesData.map((country, index) => {
                    return (
                      <React.Fragment key={index}>
                        <li
                          className="phone__country-content"
                          onClick={() => selectCountryHandler(country)}
                        >
                          <span
                            className={"flag flag-" + country.countryCode}
                          ></span>
                          <span className="phone__country-name">
                            {country.country}
                            <span className="phone__country-code">
                              +{country.dialCode}
                            </span>
                          </span>
                        </li>
                      </React.Fragment>
                    );
                  })}
                </ul>
              ) : null}
            </React.Fragment>
          ) : (
            <img className="input__icon" src={props.img} alt="icon img"></img>
          )}

          <input
            defaultValue={phone_number.current}
            ref={props.refValue}
            disabled={props.disabled ? props.disabled : false}
            placeholder={props.placeholder}
            onKeyPress={(evt: any) => {
              var theEvent: any = evt || window.event;

              // Handle paste
              // if (theEvent.type === 'paste') {
              //    // @ts-ignore
              //     key = window.clipboardData.getData('Text')
              // } else {
              // Handle key press
              var key = theEvent.keyCode || theEvent.which;
              key = String.fromCharCode(key);
              // }
              var regex = /[0-9]|\./;
              if (!regex.test(key)) {
                theEvent.returnValue = false;
                if (theEvent.preventDefault) theEvent.preventDefault();
              }
            }}
            name={props.name}
            className={
              props.errors
                ? props.errors[props.name]
                  ? "phone__input-error"
                  : "phone__input"
                : "phone__input"
            }
            onChange={
              props.label === "Email or Phone"
                ? (e: any) => props.toggleEmailOrPhone(e)
                : undefined
            }
            style={props.styleAddressForm?.input}
          ></input>
          {
            // props.errors[props.name] ? (
            //   <span className="phone__cross">
            //     <img src={cross} alt="cross"></img>
            //   </span>
            // ) : null
            // props.showErrormsg ? null : (
            //   <span className="phone__cross">
            //     <img src={tick} alt="tick"></img>
            //   </span>
            // )
          }
        </div>
        <div className="phone__errormsg">
          <ErrorMessage
            errors={props.errors}
            name={props.name}
            render={({ message }) => <div>{message}</div>}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Phone;
