import React, { useEffect, useState, Fragment, useRef } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { themeColor } from "../../constants/themeColor";
import classes from "./Header.module.scss";
import PublishIcon from "@material-ui/icons/Publish";
import * as actions from "../../AppContext/actions/actions";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CachedIcon from "@material-ui/icons/Cached";
import TeamListDropdown from "../../components/TeamListDropdown/TeamListDropdown";
import UserTypeDropdown from "../../components/UserTypeDropdown/UserTypeDropdown";
import ResourceListDropdownForHeader from "../../components/ResourceListDropdownForHeader/ResourceListDropdownForHeader";
import headerDropdownService from "../../services/headerDropdown.service";
import Btn from "../Button/Button";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { socketToasterMessageSubject } from "../../utils/socket";
import useToastRight from "../ToastRight/hooks/useToastRight";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import useLocalStorage from "../../utils/localStorage";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import CommonEndpoints from "../../services/commonApi.service";
import { userPermissionRoles } from "../../constants/userPermissionRoles";
import Input from "../../components/Input/Input";
import DateTimeFormatter from "../../utils/DateFormatter";
// import {MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateTimePicker from "../../components/TemplatesCommonMethods/DateTimePicker";
import { useForm } from "react-hook-form";
import MomentUtils from "@date-io/moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// import './monthpicker.css'

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Grid, Typography } from "@material-ui/core";
import useToast from "../Toast/hooks/useToast";
import { sidebarSubject } from "../HeaderSidebarWrapper/HeaderSidebarWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  search: {
    width: "100px",
    height: "10px",
  },
}));
// import Header from "../Header/Header";
const Header = (props: any) => {
  const [selectedTeamId, setTeamId] = useState("");
  const [appData, dispatch]: any = useAppContext();
  const location = useLocation();
  const history = useHistory();
  const toaster: any = useToast();
  const toasterRight = useToastRight();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const MonthRangePicker: any = require("react-monthrange-picker");
  const loginData: any = useLocalStorage.getItem("loginData");
  const Permission: any = useLocalStorage.getItem("Permission");
  const ApiService = useApiService();

  const setMonths = () => {
    let d: any = new Date();
    d.setMonth(d.getMonth() - 2);

    return new Date(d);
  };

  const [startDate, handleStartDate]: any = useState(setMonths());
  const [endDate, handleEndDate]: any = useState(new Date());

  const [profileImage, setProfileImage]: any = useState("");
  var moment = require("moment");
  require("moment-range");
  const {
    register,
    control,
    handleSubmit,
    reset,

    errors,
    setValue,
  } = useForm();

  const newMinuteInDateForNewJob = new Date(
    new Date().setMinutes(new Date().getMinutes() + 20)
  );
  const [selectedDate, handleDateChange] = useState(new Date());

  useEffect(() => {
    // let headerSubscription = headerDropdownService.subscribe(
    //   dropdownUpdatedFromHeader
    // );

    let sidebar = sidebarSubject.subscribe(dropdownUpdatedFromHeader);
    let socketSubscription: any = socketToasterMessageSubject.subscribe(
      (res: any) => {
        // handleSocketResponse(res);
      }
    );
    useLocalStorage.removeItem("startDate");
    useLocalStorage.removeItem("endDate");
    useLocalStorage.removeItem("monthObject");

    return () => {
      sidebar.unsubscribe();
      socketSubscription.unsubscribe();
      //  headerSubscriptionimage.unsubscribe();
    };
  }, []);
  if (
    window.location.href === "/dashboard" ||
    window.location.href === "/profile"
  ) {
    handleStartDate(setMonths());
    handleEndDate(new Date());
  }
  if (
    window.location.href === "/analytics" ||
    window.location.href === "/profile"
  ) {
    handleDateChange(new Date());
  }
  const dropdownUpdatedFromHeader = (dropdownData: any) => {
    switch (dropdownData.type) {
      case "resetAnalytics":
        handleStartDate(setMonths());
        handleEndDate(new Date());
        break;
      case "resetDashboard":
        handleDateChange(new Date());
        break;
      default:
        break;
    }
  };

  const triggerActionFromHeader = (action: string) => {
    const dropdownData = {
      type: action,
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
    //headerDropdownService.setData(dropdownData);
  };

  const teamListDropdownUpdated = (data: any) => {
    setTeamId(data.teamId);
  };

  const logoutFromDashboard = () => {
    //localStorage.clear();
    dispatch(actions.onLogout());
    // useLocalStorage.removeItem('startDate')
    // useLocalStorage.removeItem('endDate')
    window.location.href = "/login";
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    history.push("/editprofile");
    setAnchorEl(null);
  };
  function monthDiff(d1: any, d2: any) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  return (
    <Fragment>
      <div className={classes["header"]}>
        <div className={classes["header__flex"]}>
          <div className={classes["header__flex__left-side"]}>
            <div>
              {/* {location.pathname === "/dashboard"?handleDateChange(new Date()):null} */}
              {location.pathname === "/dashboard" ? (
                <>
                  <div className={classes.pickerIcon}>
                    <Grid container alignItems={"center"}>
                      <CalendarTodayIcon
                        className={classes["dropdown-pre-icon"]}
                        //style={{ color: themeColor.iconsColor }}
                      />
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          variant="inline"
                          openTo="month"
                          views={["year", "month"]}
                          value={selectedDate}
                          minDate={new Date("2017-01-01")}
                          // maxDate={new Date()}
                          onChange={(data: any) => {
                            handleDateChange(data);

                            let date: any = moment(data, "YYYY/MM/DD");

                            console.log(date);
                            let month: any = date.format("M");
                            let year: any = date.format("YYYY");
                            console.log(month);
                            console.log(year);
                            let dateObj: any = { month: month, year: year };
                            useLocalStorage.setItem("monthObject", dateObj);
                            triggerActionFromHeader("DateChanged");
                          }}
                          style={{ cursor: "pointer" }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          maxDate={new Date()}
                          PopoverProps={{
                            anchorOrigin: {
                              horizontal: "center",
                              vertical: "bottom",
                            },
                            transformOrigin: {
                              horizontal: "center",
                              vertical: "bottom",
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </div>
                </>
              ) : (
                ""
              )}

              {location.pathname === "/analytics" ? (
                <Grid container alignItems={"center"}>
                  <CalendarTodayIcon
                    className={classes["dropdown-pre-icon"]}
                    //style={{ color: themeColor.iconsColor }}
                  />

                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      variant="inline"
                      openTo="month"
                      views={["year", "month"]}
                      // textFieldStyle={{width: '20%'}}
                      minDate={new Date("2017-01-01")}
                      label="Start Month"
                      value={startDate}
                      onChange={(data: any) => {
                        handleStartDate(data);
                        let diff: any = monthDiff(
                          new Date(data),
                          new Date(endDate)
                        );
                        if (diff <= 12) {
                          let date: any = moment(data, "YYYY/MM/DD");
                          console.log(date);
                          let month: any = date.format("M");
                          let year: any = date.format("YYYY");

                          const startMonthData = {
                            type: "startDateChanged",
                            data: { month: month, year: year },
                          };
                          headerDropdownService.emit(startMonthData);
                        } else {
                          handleStartDate(
                            useLocalStorage.getItem("startDate")
                              ? new Date(useLocalStorage.getItem("startDate"))
                              : setMonths()
                          );
                          toaster.addToast({
                            message:
                              "Months Range Cannot Be Greater Than 12 Months",
                            type: "error",
                            timeout: 3000,
                          });
                        }
                      }}
                      style={{ cursor: "pointer", width: "125px" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      maxDate={new Date()}
                      PopoverProps={{
                        anchorOrigin: {
                          horizontal: "center",
                          vertical: "bottom",
                        },
                        transformOrigin: {
                          horizontal: "center",
                          vertical: "bottom",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  {/* <span style={{color:"black",marginRight:"15px",}}>to</span>   */}

                  <CalendarTodayIcon
                    className={classes["dropdown-pre-icon"]}
                    //style={{ color: themeColor.iconsColor }}
                  />

                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      variant="inline"
                      openTo="month"
                      label="End Month"
                      views={["year", "month"]}
                      value={endDate}
                      minDate={startDate}
                      onChange={(data: any) => {
                        let diff: any = monthDiff(
                          new Date(startDate),
                          new Date(data)
                        );
                        if (diff <= 12) {
                          handleEndDate(data);
                          let date: any = moment(data, "YYYY/MM/DD");
                          console.log(date);
                          let month: any = date.format("M");
                          let year: any = date.format("YYYY");
                          const endMonthData = {
                            type: "endDateChanged",
                            data: { month: month, year: year },
                          };
                          headerDropdownService.emit(endMonthData);
                        } else {
                          handleEndDate(new Date());
                          toaster.addToast({
                            message:
                              "Months Range Cannot Be Greater Than 12 Months",
                            type: "error",
                            timeout: 3000,
                          });
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      maxDate={new Date()}
                      PopoverProps={{
                        anchorOrigin: {
                          horizontal: "center",
                          vertical: "bottom",
                        },
                        transformOrigin: {
                          horizontal: "center",
                          vertical: "bottom",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : (
                ""
              )}
            </div>
            <div className={classes["header__flex__item-spacing"]}>
              {location.pathname == "/dashboard" &&
              Permission &&
              Permission["Read_Resources"] ? (
                // <ResourceListDropdownForHeader  inputProps={{ 'aria-label': 'naked' }} showAllResource={true} teamId={selectedTeamId} showIcon={true} variant={"standard"} location="header"></ResourceListDropdownForHeader>:null
                <ResourceListDropdownForHeader
                  teamId={selectedTeamId}
                ></ResourceListDropdownForHeader>
              ) : null}
            </div>
          </div>

          <div className={classes["header__notification-corner__image"]}>
            {/* <img
              className={
                classes["header__notification-corner__image__image-cover"]
              }
              src={BellIcon}
              alt="notifation bell icon"
            /> */}

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="menu-list-grow">
                        <MenuItem onClick={handleClickOpen}>
                          Edit Profile
                        </MenuItem>
                        <MenuItem onClick={logoutFromDashboard}>
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            {/* {(loginData && loginData.user_image) || profileImage ? (
              <img
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "25px",
                  border: "2px solid #fffff",
                }}
                className={classes["header__logout-icon"]}
                src={profileImage || loginData.user_image}
                alt="hi"
                onClick={handleClick}
              ></img>
            ) : (
              <AccountCircleIcon
                className={classes["header__logout-icon"]}
                style={{ color: themeColor.iconsColor }}
                fontSize="large"
                onClick={handleClick}
              ></AccountCircleIcon>
            )} */}
            {location.pathname === "/dashboard" ? (
              <>
                <Btn
                  className={classes.registerBtn}
                  onClick={() => {
                    triggerActionFromHeader("openRegisterModal");
                  }}
                  type={"button"}
                  btnText={"Register"}
                ></Btn>
              </>
            ) : null}

            <ExitToAppIcon
              onClick={logoutFromDashboard}
              className={classes["header__logout-icon"]}
              style={{ color: themeColor.iconsColor }}
            ></ExitToAppIcon>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
